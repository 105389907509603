import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

import useAdminStyles from '../styles';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { B2BSubscriptionPlan, GetB2BSubscriptionPlanAdmin } from '../../types';
import { formatDinero, getDinero, joinNameParts } from '../../utilities';
import HasPermission from '../../utilities/can';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import ManageB2BSubscriptionPlanEditForm from './editForm';
import StatusButton from '../common/status-button';
import storeContext from '../../contexts/store-context';
import { useStyles } from '../manage-user';
import { useUserService } from '../../contexts/user-context';
import ConfirmationDialog from '../common/confirmation-dialog';
import { Switch } from '@material-ui/core';

type State = {
    isLoading: boolean;
    isDeleting: boolean;
    renderAddEditForm: boolean;
    menuAnchorEle: HTMLElement | null;
    activeplan: B2BSubscriptionPlan | null;
    selectedRows: Array<TableResultRow>;
    isConfirmationDialogOpen: boolean;
    plans: Array<GetB2BSubscriptionPlanAdmin>;
};

const ManageB2BSubscriptionPlan = () => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();

    const history = useHistory();
    const userService = useUserService();
    const { appAction, subscriptionAction } = storeContext();

    const canRead = HasPermission(userService.user, 'MANAGE_B2B_SUBSCRIPTION_PLAN', 'READ');
    const canUpdate = HasPermission(userService.user, 'MANAGE_B2B_SUBSCRIPTION_PLAN', 'UPDATE');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAllBusinessplans();
    }, []);

    const [state, setState] = useState<State>({
        isLoading: false,
        isDeleting: false,
        renderAddEditForm: false,
        menuAnchorEle: null,
        activeplan: null,
        selectedRows: [],
        isConfirmationDialogOpen: false,
        plans: []
    });

    const getAllBusinessplans = async () => {
        setState((prevState) => ({
            ...prevState,
            isLoading: true
        }));
        const plans = await subscriptionAction()?.getAllSubscriptionPlans();
        setState((prevState) => ({
            ...prevState,
            plans: plans || [],
            isLoading: false,
            isDeleting: false,
            isConfirmationDialogOpen: false
        }));
    };

    const onIsActiveChange = async (isActive: boolean, id: number) => {
        const activeCategory = state.plans.find((p) => p.id === id);
        if (!activeCategory) {
            return;
        }
        await subscriptionAction()?.saveSubscription({
            ...activeCategory,
            isActive
        });

        setState((prevState) => {
            const newPlans = prevState.plans.map((p) => {
                if (p.id === id) {
                    return { ...p, isActive };
                }
                return p;
            });
            return { ...prevState, plans: newPlans };
        });
    };


    const headCells: Array<HeadCell> = [
        { id: 'name', label: 'Name' },
        { id: 'subscriptionPlan', label: 'Subscription Plan' },
        { id: 'orderFee', label: 'Order fee $' },
        { id: 'giftCardFee', label: 'Gift Card Fee %', align: 'left' },
        { id: 'cardLimit', label: 'Card Creation Quota', align: 'left' },
        { id: 'cardCreationFee', label: 'Over-Quota Card Fee $', align: 'left' },
        { id: 'incentiveLimit', label: 'Incentive Card Allowance', align: 'left' },
        { id: 'incentiveFee', label: 'Incentive Fee %', align: 'left' },
        { id: 'isActive', label: 'Is Active' },
        { id: 'options', label: '', align: 'right' }
    ];

    const tableRows: Array<TableResultRow> = state.plans.map((plan) => {
        return {
            id: {
                text: plan.id?.toString() || ''
            },
            name: {
                align: 'left',
                text: plan.name
            },
            subscriptionPlan: {
                align: 'left',
                text: `${formatDinero(getDinero(plan.subscriptionFeePrice))} every ${
                    plan.subscriptionFeeInterval
                } month(s)`
            },
            orderFee: {
                align: 'left',
                text: formatDinero(getDinero(plan.orderFee))
            },
            giftCardFee: {
                align: 'left',
                text: plan.giftCardFee + '%'
            },
            cardLimit: {
                align: 'left',
                text: plan.cardCreationLimit?.toString() || '-'
            },
            cardCreationFee: {
                align: 'left',
                text: plan.cardCreationFee ?  formatDinero(getDinero(plan.cardCreationFee)) : '-'
            },
            incentiveLimit: {
                align: 'left',
                text: plan.sendIncentiveLimit?.toString() || '-'
            },
            incentiveFee: {
                align: 'left',
                text: plan.incentiveFee ?  plan.incentiveFee + '%' : '-'
            },
            isActive: {
                align: 'left',
                text: '',
                element: canUpdate ? (
                    <Switch
                        checked={plan.isActive}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onIsActiveChange(!plan.isActive, plan.id);
                        }}
                        color="primary"
                    />
                ) : (
                    <StatusButton
                        options={[
                            { type: 'ACTIVE', text: 'Active', color: 'active' },
                            { type: 'INACTIVE', text: 'Inactive', color: 'warning' }
                        ]}
                        type={plan.isActive ? 'ACTIVE' : 'INACTIVE'}
                    />
                )
            },
            options: {
                align: 'right',
                text: '',
                helperText:
                    (plan.user?.userId &&
                        `updated by - ${plan.user?.firstName ? joinNameParts(plan.user) : plan.user?.email || ''}${
                            plan?.updatedAt && ' - '
                        }${plan?.updatedAt && moment(new Date(plan?.updatedAt)).fromNow()}`) ||
                    undefined,
                element:
                    (canUpdate && (
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setState((prevState) => ({
                                    ...prevState,
                                    menuAnchorEle: e.currentTarget,
                                    activeplan: plan
                                }));
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )) ||
                    undefined
            }
        };
    });

    const headerComponent = (
        <Grid item={true} xs={12} className={classes.headerComponent}>
            <Typography className={classes.tableHeading}>B2B Subscription Plans</Typography>
            <Button
                onClick={() =>
                    setState((prevState) => ({ ...prevState, renderAddEditForm: true, activeCategoryId: null }))
                }
                size="small"
                color="primary"
                variant="contained"
            >
                <AddIcon />
                &nbsp;Create New Plan
            </Button>
        </Grid>
    );

    const handleRowClick = (planId: string) => {
        if (!canUpdate) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            renderAddEditForm: true,
            activeplan: prevState.plans.find((u) => u.id === +planId) || null
        }));
    };

    const savePlan = async (plan: B2BSubscriptionPlan) => {
        const success = await subscriptionAction()?.saveSubscription({ ...plan, id: state.activeplan?.id });
        if (success) {
            closeForm();
            getAllBusinessplans();
        }
    };

    const handleMenuClose = () => {
        setState((prevState) => ({ ...prevState, menuAnchorEle: null, activeplan: null }));
    };

    const onDeleteConfirm = async () => {
        const ids: number[] = [];

        const idsToDelete = state.selectedRows.map((row) => +row.id.text);
        Array.prototype.push.apply(ids, idsToDelete);

        setState((prevState) => ({ ...prevState, isDeleting: true }));
        const plansToDelete = state.plans.filter((p) => ids.includes(p.id)).map((p) => ({ ...p, isDeleted: true }));
        await subscriptionAction()?.updateAllSubscriptions(plansToDelete);

        closeForm();
        getAllBusinessplans();
    };

    const closeForm = () => setState((prevState) => ({ ...prevState, renderAddEditForm: false, activeplan: null }));

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                {state.renderAddEditForm && (
                    <ManageB2BSubscriptionPlanEditForm plan={state.activeplan} onCancel={closeForm} onSave={savePlan} />
                )}

                <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                    <MenuItem
                        disabled={!canUpdate}
                        onClick={() => {
                            if (state.activeplan) {
                                handleRowClick((state.activeplan?.id || -1).toString());
                            }
                            setState((prevState) => ({ ...prevState, menuAnchorEle: null }));
                        }}
                    >
                        Edit
                    </MenuItem>
                </Menu>

                <TableComponent
                    headerComponent={headerComponent}
                    showPaginator={{ bottom: true }}
                    showCheckbox={true}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    selectedRows={state.selectedRows}
                    onDelete={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    onCheckboxSelect={(selectedRows) => setState((prevState) => ({ ...prevState, selectedRows }))}
                    keyField="id"
                    rowTooltip={(canUpdate && 'Click to edit') || undefined}
                    onRowClick={handleRowClick}
                    fillEmptyRows={false}
                    noOfRowsPerPage={10}
                />
            </Container>

            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={'Click CONFIRM to delete the category'}
                isLoading={state.isDeleting}
                open={state.isConfirmationDialogOpen}
                onClose={() =>
                    setState({
                        ...state,
                        isConfirmationDialogOpen: false,
                        activeplan: null,
                        menuAnchorEle: null
                    })
                }
                onConfirm={onDeleteConfirm}
            />
        </Grid>
    );
};

export default ManageB2BSubscriptionPlan;
