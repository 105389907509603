import { lazy } from 'react';
import { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import ManageBusinessUser from './components/manage-business-user';
import ManageUser from './components/manage-user';
import Category from './components/category';
import AdminDashboard from './components/dashboard';
import MlCategory from './components/ml-category';
import MyAccount from './components/my-account';

import { NotFound } from './components/not-found';
import PromoCode from './components/promo-code';
import Users from './components/users';
import ManageProduct from './components/manage-product';
import ManagePricing from './components/manage-pricing';
import CompareProduct from './components/compare-product';
import ManageTheme from './components/manage-theme';
import FraudScoreSlab from './components/fraud-score-slab';
import TransactionLimitSettings from './components/manage-transaction-limit-settings';
import ManageInfluencer from './components/manage-influencer';
import ManagePayout from './components/manage-payout';
import ManageInfluencerDetails from './components/manage-influencer/manage-influencer-details';
import ManageFundTransactions from './components/manage-fund-transactions';
import ManageRatings from './components/manage-ratings';
import ManageB2BSubscriptionPlan from './components/manage-b2b-subscription';
import ManageBusinessOrderPayout from './components/manage-business-order-payout';
import ManageMerchant from './components/manage-merchants';
import ManageBlackListedEmails from './components/manage-black-listed-emails';
import OtpVerification from './components/auth/otp-verification';
import ManageExchangeRates from './components/manage-exchange-rates';

type ParamType = Record<string, string>;

export type RouteType = RouteConfig & {
    key: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.ComponentType<RouteConfigComponentProps<any>> | React.ComponentType;
    privateRoute?: boolean;
    loadData?: (params?: ParamType) => Promise<null>;
};

const ResetPassword = lazy(() =>
    import('./components/auth/reset-password').then((r) => ({ default: r.ResetPassword }))
);
const RecoverPassword = lazy(() =>
    import('./components/auth/recover-password').then((r) => ({ default: r.RecoverPassword }))
);
const AccountLocked = lazy(() =>
    import('./components/auth/account-locked').then((r) => ({ default: r.AccountLocked }))
);
const Login = lazy(() => import('./components/auth/login').then((r) => ({ default: r.Login })));

const ROUTES: Array<RouteType> = [
    { key: 1, path: '/', exact: true, component: Login },
    { key: 1, path: '/account-locked/:time', exact: true, component: AccountLocked },

    { key: 6, path: '/login', component: Login, exact: true },
    { key: 7, path: '/recover-password', component: RecoverPassword, exact: true },
    {
        key: 8,
        path: '/reset-password/:userId/:accessToken',
        component: ResetPassword,
        exact: true
    },

    { key: 16, path: '/dashboard', component: AdminDashboard, exact: true, privateRoute: true },
    { key: 17, path: '/users', component: Users, exact: true, privateRoute: true },
    { key: 18, path: '/my-account', component: MyAccount, exact: true, privateRoute: true },
    { key: 19, path: '/promo-codes', component: PromoCode, exact: true, privateRoute: true },
    { key: 20, path: '/categories', component: Category, exact: true, privateRoute: true },
    { key: 21, path: '/ml-categories', component: MlCategory, exact: true, privateRoute: true },
    { key: 22, path: '/manage-business-user', component: ManageBusinessUser, exact: true, privateRoute: true },
    { key: 23, path: '/manage-merchants', component: ManageMerchant, exact: true, privateRoute: true },
    { key: 24, path: '/manage-b2b-subscription', component: ManageB2BSubscriptionPlan, exact: true, privateRoute: true },
    { key: 25, path: '/manage-business-order-payout', component: ManageBusinessOrderPayout, exact: true, privateRoute: true },
    { key: 26, path: '/manage-fund-transactions', component: ManageFundTransactions, exact: true, privateRoute: true },
    { key: 27, path: '/manage-user', component: ManageUser, exact: true, privateRoute: true },
    { key: 28, path: '/manage-ratings', component: ManageRatings, exact: true, privateRoute: true },
    { key: 29, path: '/manage-product', component: ManageProduct, exact: true, privateRoute: true },
    { key: 30, path: '/manage-pricing', component: ManagePricing, exact: true, privateRoute: true },
    { key: 31, path: '/compare-product', component: CompareProduct, exact: true, privateRoute: true },
    { key: 32, path: '/manage-theme', component: ManageTheme, exact: true, privateRoute: true },
    { key: 33, path: '/manage-fraud-score-slab', component: FraudScoreSlab, exact: true, privateRoute: true },
    {
        key: 34,
        path: '/manage-transaction-limit-settings',
        component: TransactionLimitSettings,
        exact: true,
        privateRoute: true
    },
    {
        key: 35,
        path: '/manage-influencer',
        component: ManageInfluencer,
        exact: true,
        privateRoute: true
    },
    {
        key: 36,
        path: '/manage-influencer/:influencerUserId',
        component: ManageInfluencerDetails,
        exact: true,
        privateRoute: true
    },
    {
        key: 37,
        path: '/manage-payout',
        component: ManagePayout,
        exact: true,
        privateRoute: true
    },
    {
        key: 38,
        path: '/manage-black-listed-emails',
        component: ManageBlackListedEmails,
        exact: true,
        privateRoute: true
    },
    {
        key: 39,
        path: '/otp-verification/:token',
        component: OtpVerification,
        exact: true,
        privateRoute: false
    },
    {
        key: 40,
        path: '/manage-exchange-rates',
        component: ManageExchangeRates,
        exact: true,
        privateRoute: true
    },

    { key: 41, path: '*', component: NotFound, exact: true }
];

export default ROUTES;
