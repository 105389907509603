import React, { useState } from 'react';
import { useHistory, useLocation, Link as RouterLink  } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';

import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CategoryIcon from '@material-ui/icons/Category';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FilterDrama from '@material-ui/icons/FilterDrama';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import GroupIcon from '@material-ui/icons/Group';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import ReceiptOutlined from '@material-ui/icons/ReceiptOutlined';
import GroupAdd from '@material-ui/icons/GroupAdd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ReceiptSharpIcon from '@material-ui/icons/ReceiptSharp';
import StarRateIcon from '@material-ui/icons/StarRate';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import PaymentRounded from '@material-ui/icons/PaymentRounded';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import MonetizationOnTwoTone from '@material-ui/icons/MonetizationOnTwoTone';


import storeContext from '../../contexts/store-context';
import { useUserService } from '../../contexts/user-context';
import { PrivilegeTypeEnum } from '../../types';
import HasPermission from '../../utilities/can';
import { MenuType } from './menus';
import { joinNameParts } from '../../utilities';

import cardmoolaLogo from '../../assets/images/cardmoola-logo-headermenu.svg';
import UserAvatar from '../common/user-avatar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import { useToasterData } from '../../contexts/toaster-context';

export const DRAWER_WIDTH = 250;

export const useStyles = makeStyles((theme) => ({
    appbar: {
        top: '0px',
        position: 'fixed',
        margin: 'auto',
        display: 'flex',
        flex: '0 0 auto',
        width: '100%',
        height: 64,
        maxHeight: 64,
        minHeight: 64,
        [theme.breakpoints.up('md')]: {
            zIndex: theme.zIndex.drawer + 1
        }
    },
    toolbar: {
        height: 64,
        maxHeight: 64,
        minHeight: 64,
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& $inner': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    homeButton: {
        lineHeight: '22px',
        '& svg': {
            marginRight: 8,
            fontSize: 20
        }
    },
    drawerOuter: {
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.up('md')]: {
            flexShrink: 0
        }
    },
    drawer: {
        width: DRAWER_WIDTH
    },
    drawerPaper: {
        width: DRAWER_WIDTH,
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    isSelected: {
        color: theme.palette.primary.main,
        '& svg': {
            color: theme.palette.primary.main
        }
    },
    menuCardmoolaLogo: {
        cursor: 'pointer',
        padding: 4,
        margin: '12px auto',
        width: 92,
        height: 'auto'
    },
    avatarContainer: {
        width: DRAWER_WIDTH,
        padding: 16
    },
    userAvatar: {
        margin: 'auto'
    },
    name: {
        marginTop: 24,
        fontFamily: `'Work Sans', sans-serif`,
        fontSize: 20,
        fontWeight: 800,
        textAlign: 'center',
        wordBreak: 'break-word'
    },
    email: {
        textAlign: 'center',
        marginTop: 6,
        wordBreak: 'break-word'
    },
    logoOnly: {
        display: 'flex',
        width: 82,
        height: 64,
        cursor: 'pointer'
    },
    logoOnlyContainer: {
        marginTop: 48
    },
    logoOnlyContainerRoot: {
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '0 0 auto',
        width: '100%'
    },
    menuButton: {},
    title: {},
    inner: {}
}));

type DrawerHeaderProps = {
    setToggleDrawer: () => void;
};
export const DrawerHeader = (props: DrawerHeaderProps) => {
    const { setToggleDrawer } = props;

    const classes = useStyles();
    const history = useHistory();
    const userService = useUserService();
    const isUserSignedIn = !!userService?.user;
    const fullName = joinNameParts(userService?.user);

    return (
        <React.Fragment>
            {!isUserSignedIn && (
                <img
                    src={cardmoolaLogo}
                    className={classes.menuCardmoolaLogo}
                    onClick={() => {
                        setToggleDrawer();
                        history.push('/');
                    }}
                    alt="CardMoola"
                />
            )}

            {isUserSignedIn && (
                <div className={classes.avatarContainer}>
                    <UserAvatar className={classes.userAvatar} user={userService?.user} />

                    {fullName && (
                        <Typography className={classes.name} color="primary">
                            {fullName}
                        </Typography>
                    )}

                    {userService?.user?.email && (
                        <Typography className={classes.email} color="textSecondary">
                            {userService?.user?.email}
                        </Typography>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

type AdminMenuType = MenuType & {
    key: typeof PrivilegeTypeEnum[number] | 'MANAGE_MERCHANTS' | 'DASHBOARD' | 'MY_ACCOUNT';
};

export const MENU_ITEMS: Array<AdminMenuType> = [
    {
        key: 'DASHBOARD',
        label: 'Dashboard',
        route: '/dashboard',
        icon: <DesktopWindowsIcon />
    },
    {
        key: 'MANAGE_ADMIN_USER',
        label: 'Manage Admin Users',
        route: '/users',
        icon: <PeopleAltIcon />
    },
    {
        key: 'PROMO_CODE',
        label: 'Promo Codes',
        route: '/promo-codes',
        icon: <LocalOfferIcon />
    },
    {
        key: 'CATEGORY',
        label: 'Categories',
        route: '/categories',
        icon: <CategoryIcon />
    },
    {
        key: 'ML_CATEGORY',
        label: 'ML Categories',
        route: '/ml-categories',
        icon: <FilterDrama />
    },
    {
        key: 'APPROVE_BUSINESS_USER',
        label: 'Manage Business Users',
        route: '/manage-business-user',
        icon: <BusinessCenterIcon />
    },
    {
        key: 'MANAGE_MERCHANTS',
        label: 'Manage Merchants',
        route: '/manage-merchants',
        icon: <CardGiftcardIcon />
    },
    {
        key: 'MANAGE_B2B_SUBSCRIPTION_PLAN',
        label: 'Manage B2B Subscriptions',
        route: '/manage-b2b-subscription',
        icon: <CardMembershipIcon />
    },
    {
        key: 'MANAGE_FUND_TRANSACTIONS',
        label: 'Manage Business Fund Transactions',
        route: '/manage-fund-transactions',
        icon: <ReceiptSharpIcon />
    },
    {
        key: 'MANAGE_BUSINESS_ORDER_PAYOUT',
        label: 'Manage Merchant Order Payout',
        route: '/manage-business-order-payout',
        icon: <PaymentRounded />
    },
    {
        key: 'MANAGE_USER',
        label: 'Manage Users',
        route: '/manage-user',
        icon: <GroupIcon />
    },
    {
        key: 'MANAGE_RATINGS',
        label: 'Manage Ratings',
        route: '/manage-ratings',
        icon: <StarRateIcon />
    },
    {
        key: 'MANAGE_PRODUCT',
        label: 'Manage Products',
        route: '/manage-product',
        icon: <MarkunreadMailboxIcon />
    },
    {
        key: 'MANAGE_PRICING',
        label: 'Manage Pricing',
        route: '/manage-pricing',
        icon: <AttachMoneyIcon />
    },
    {
        key: 'COMPARE_PRODUCT',
        label: 'Compare Products',
        route: '/compare-product',
        icon: <CompareArrowsIcon />
    },
    {
        key: 'MANAGE_THEME',
        label: 'Manage Theme',
        route: '/manage-theme',
        icon: <ColorLensIcon />
    },
    {
        key: 'MANAGE_FRAUD_SCORE_SLAB',
        label: 'Manage Fraud Score Slab',
        route: '/manage-fraud-score-slab',
        icon: <AcUnitIcon />
    },
    {
        key: 'MANAGE_TRANSACTION_LIMIT_SETTINGS',
        label: 'Manage Transaction Limit Settings',
        route: '/manage-transaction-limit-settings',
        icon: <ReceiptOutlined />
    },
    {
        key: 'MANAGE_BLACK_LISTED_EMAILS',
        label: 'Manage Blacklisted Emails',
        route: '/manage-black-listed-emails',
        icon: <EmailOutlinedIcon />
    },
    {
        key: 'MANAGE_EXCHANGE_RATES',
        label: 'Manage Exchange Rates',
        route: '/manage-exchange-rates',
        icon: <MonetizationOnTwoTone />
    },
    {
        key: 'MANAGE_INFLUENCER',
        label: 'Manage Influencers',
        route: '/manage-influencer',
        icon: <GroupAdd />
    },
    {
        key: 'MANAGE_PAYOUT',
        label: 'Manage Influencer Payout Transactions',
        route: '/manage-payout',
        icon: <MonetizationOnIcon />
    },
    {
        key: 'MY_ACCOUNT',
        label: 'My Account',
        route: '/my-account',
        icon: <AccountCircleIcon />
    }
];

type State = {
    isDrawerOpen: boolean;
};

const ElevationScroll = (props: { children: JSX.Element }) => {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0
    });
};

const Header = () => {
    const classes = useStyles();
    const history = useHistory();
    const currentLocation = useLocation();
    const userService = useUserService();

    const toasterContext = useToasterData();

    const { appState } = storeContext();

    const theme = useTheme();
    const isAboveMDWidth = useMediaQuery(theme.breakpoints.up('md'));

    const { userSessionAction } = storeContext();

    const [state, setState] = useState<State>({
        isDrawerOpen: false
    });

    const logo = (
        <img src={cardmoolaLogo} className={classes.logoOnly} onClick={() => history.push('/')} alt="cardmoola" />
    );

    if (appState.header.isHidden) {
        return null;
    }

    if (appState.header.showMiniHeader) {
        return (
            <Grid item={true} xs={12} className={classes.logoOnlyContainerRoot}>
                <Box className={classes.logoOnlyContainer}>{logo}</Box>
            </Grid>
        );
    }

    const toggleDrawer = (isDrawerOpen?: boolean) => setState({ ...state, isDrawerOpen: isDrawerOpen || false });
    const filterByRole = (menuItems: Array<AdminMenuType>) =>
        menuItems.filter((m) => {
            switch (m.key) {
                case 'MANAGE_ADMIN_USER':
                    return HasPermission(userService.user, 'MANAGE_ADMIN_USER', 'READ');
                case 'PROMO_CODE':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'CATEGORY':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'ML_CATEGORY':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'APPROVE_BUSINESS_USER':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'MANAGE_BUSINESS_ORDER_PAYOUT':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'MANAGE_USER':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'MANAGE_PRODUCT':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'MANAGE_PRICING':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'MANAGE_THEME':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'MANAGE_FRAUD_SCORE_SLAB':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'MANAGE_TRANSACTION_LIMIT_SETTINGS':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'COMPARE_PRODUCT':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'MANAGE_INFLUENCER_REFERRAL_PAYMENT':
                    return HasPermission(userService.user, m.key, 'READ');
                case 'MY_ACCOUNT':
                case 'DASHBOARD':
                default:
                    return true;
            }
        });

    const match = MENU_ITEMS.find((m) => currentLocation.pathname === m.route);

    return (
        <ElevationScroll>
            <React.Fragment>
                <AppBar position="sticky" className={classes.appbar}>
                    <Toolbar className={classes.toolbar}>
                        <Grid item={true} className={classes.inner}>
                            <Hidden mdUp={true}>
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={(e) => toggleDrawer(true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Hidden>

                            <Typography variant="h6" className={classes.title}>
                                Admin Panel
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                className={classes.homeButton}
                                onClick={(e) => history.push('/dashboard')}
                            >
                                <HomeIcon />
                                Home
                            </Button>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <SwipeableDrawer
                    anchor="left"
                    open={state.isDrawerOpen}
                    onClose={(e) => toggleDrawer()}
                    onOpen={(e) => toggleDrawer(true)}
                    variant={(isAboveMDWidth && 'permanent') || undefined}
                    className={classes.drawerOuter}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <Hidden smDown={true}>
                        <Toolbar />
                    </Hidden>

                    <DrawerHeader setToggleDrawer={toggleDrawer} />

                    <div
                        className={classes.drawer}
                        role="presentation"
                        onClick={(e) => toggleDrawer()}
                        onKeyDown={(e) => toggleDrawer()}
                    >
                        <List>
                            {filterByRole(MENU_ITEMS).map((m) => {
                                const isSelected = Boolean(match?.key === m.key);
                                return (
                                    <ListItem
                                        selected={isSelected}
                                        className={(isSelected && classes.isSelected) || undefined}
                                        button={true}
                                        key={m.key}
                                        component={RouterLink}
                                        to={m.route}
                                        onClick={() => history.push(m.route)}
                                    >
                                        <ListItemIcon>{m.icon}</ListItemIcon>
                                        <ListItemText primary={m.label} />
                                    </ListItem>
                                );
                            })}
                        </List>

                        <Divider />

                        <ListItem
                            button={true}
                            onClick={() => {
                                userSessionAction()?.logout();
                                toasterContext.setToaster({
                                    isOpen: true,
                                    message: 'Logged out successfully!',
                                    severity: 'success'
                                });
                                toggleDrawer();
                                history.push('/');
                            }}
                        >
                            <ListItemIcon>
                                {' '}
                                <ExitToAppIcon />{' '}
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </div>
                </SwipeableDrawer>
            </React.Fragment>
        </ElevationScroll>
    );
};

export default Header;
