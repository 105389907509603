import React, { createContext, useReducer, useEffect, useContext, Dispatch, useCallback, useRef } from 'react';
import { initialState, reducer, StoreState } from '../reducers';
import useActions, { StoreActions, StoreActionType } from '../actions';

import { formatTimeStamp, DateFormatTypeEnum } from '../utilities';
import { AccessTokenContextProps, AuthEventEnum, useAccessTokenService } from './access-token-context';
import { ToasterContextProps, useToasterData } from './toaster-context';
import { LoginLogoutOptions } from '../actions/user-session.action';
import { ApiServiceContextProps, useApiService } from './api-service-context';
import { UserContextProps, useUserService } from './user-context';
import { IdleTimeoutContextProps, isUserIdle, useIdleTimeoutService } from './idle-timeout-context';
import { debounce } from 'lodash';

export const setLastActivityInStorage = () => {
    localStorage.setItem(AuthEventEnum.LAST_ACTIVITY, new Date().getTime().toString());
};

type Props = { children?: React.ReactNode };
export type StoreContextProviderProps = StoreState & StoreActions & { dispatch: Dispatch<StoreActionType> };
export const StoreContext = createContext<StoreContextProviderProps | undefined>(undefined);
export const StoreProvider: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
    const apiService: ApiServiceContextProps = useApiService();
    const userService: UserContextProps = useUserService();
    const accessTokenService: AccessTokenContextProps = useAccessTokenService();
    const toasterContext: ToasterContextProps = useToasterData();
    const idleTimeoutService: IdleTimeoutContextProps = useIdleTimeoutService();
    const [storeState, dispatch] = useReducer(reducer, initialState);
    const actions = useActions(
        storeState,
        apiService,
        userService,
        accessTokenService,
        toasterContext,
        idleTimeoutService,
        dispatch
    );
    const {
        userSessionAction,
        appAction,
        giftCardAction,
        promoCodeAction,
        categoryAction,
        mlCategoryAction,
        themeAction,
        influencerReferralPaymentAction,
        manageInfluencerAction,
        influencerPayoutAction,
        manageBusinessUserAction,
        subscriptionAction,
        b2bApiConfigAction,
        b2bGiftCardAction,
        manageUserAction,
        manageTransactionLimitSettingsAction,
        blackListedEmailsAction,
        otpAction,
        geoLocationAction,
        exchangeRatesAction
    } = actions;

    const debouncedSetLastActivity = useRef(
        debounce(
            () => {
                setLastActivityInStorage();
            },
            500,
            { leading: true, trailing: false }
        )
    );

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(
                'new state ==>',
                { storeState },
                formatTimeStamp(new Date(), DateFormatTypeEnum.MMM_DD_YYYY_h_mm_ss_SSS_a)
            );
        }
    }, [storeState]);

    useEffect(() => {
        fetchUserDetails();
        handleMultipleTabAuthentication();
    }, []);

    useEffect(() => {
        // this will check if user was idle and tab or browser is open
        idleTimeoutService.setIdleCallback(() => () => {
            if (!userService.user) {
                return;
            }
            // Log out the user
            userSessionAction?.logout();
            toasterContext.setToaster({
                isOpen: true,
                message: 'You have been logged out due to inactivity!',
                severity: 'warning'
            });
        });
        // used to store the last activity time of the user
        idleTimeoutService.setLastActivityCallback(() => () => {
            if (isUserIdle() || !userService.user) {
                return;
            }

            debouncedSetLastActivity.current();
        });
        // this will check if the user was idle on page load
        if (isUserIdle() && userService.user) {
            // Log out the user
            userSessionAction?.logout();
            toasterContext.setToaster({
                isOpen: true,
                message: 'You have been logged out due to inactivity!',
                severity: 'warning'
            });
            return;
        }
    }, [userService.user]); // eslint-disable-line react-hooks/exhaustive-deps

    // Multiple tabs support for login and logout
    const handleMultipleTabAuthentication = () => {
        window.addEventListener('storage', async (event) => {
            const options: LoginLogoutOptions = { skipFireEvent: true };
            if (event.key === AuthEventEnum.LOGOUT) {
                userSessionAction?.logout(options);
                return;
            }

            if (event.key === AuthEventEnum.LOGIN) {
                userSessionAction?.login(options);
            }
        });
    };

    // this function is called only once when the app is loaded
    const fetchUserDetails = async () => {
        await userSessionAction?.login();
        userSessionAction?.setLoading(false);
    };

    const providerValue: StoreContextProviderProps = {
        ...storeState,
        dispatch,
        appAction: () => appAction,
        userSessionAction: () => userSessionAction,
        giftCardAction: () => giftCardAction,
        promoCodeAction: () => promoCodeAction,
        categoryAction: () => categoryAction,
        mlCategoryAction: () => mlCategoryAction,
        themeAction: () => themeAction,
        influencerReferralPaymentAction: () => influencerReferralPaymentAction,
        manageInfluencerAction: () => manageInfluencerAction,
        influencerPayoutAction: () => influencerPayoutAction,
        manageBusinessUserAction: () => manageBusinessUserAction,
        subscriptionAction: () => subscriptionAction,
        b2bApiConfigAction: () => b2bApiConfigAction,
        b2bGiftCardAction: () => b2bGiftCardAction,
        manageUserAction: () => manageUserAction,
        manageTransactionLimitSettingsAction: () => manageTransactionLimitSettingsAction,
        blackListedEmailsAction: () => blackListedEmailsAction,
        otpAction: () => otpAction,
        geoLocationAction: () => geoLocationAction,
        exchangeRatesAction: () => exchangeRatesAction
    };
    return <StoreContext.Provider value={providerValue}>{props.children}</StoreContext.Provider>;
};

const StoreContextFn = () => {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error('Store Context is not defined');
    }
    return context;
};

export default StoreContextFn;
