import React from 'react';
import { JsonEditor } from 'json-edit-react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { OrderAttributes } from '../../../types';

const useStyles = makeStyles((theme) => ({
    form: {
        padding: 16,
        boxShadow: theme.shadows[4],
        borderRadius: 8,
        margin: '24px auto',
        '& $heading': {
            textAlign: 'left',
            fontSize: 20,
            fontWeight: 600,
            margin: '12px 0'
        }
    },
    mainContainer: {
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },
        '& $inner': {
            [theme.breakpoints.up('lg')]: {
                display: 'flex'
            }
        }
    },
    details: {
        textAlign: 'left',
        '& > div': {
            marginBottom: 8
        }
    },
    staticFields: {
        display: 'flex',
        flexFlow: 'column',
        textAlign: 'left',
        '& > span': {
            '&:first-child': {
                fontWeight: 900,
                marginRight: 12
            }
        },
        '& > input': {
            padding: 0,
            textAlign: 'left',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5',
            letterSpacing: '0.00938em'
        }
    },
    inner: {},
    heading: {}
}));

type Props = {
    order: OrderAttributes;
};

export const OrderDetails = (props: Props) => {
    const classes = useStyles();

    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestPayload = props.order.fraudRequestPayload as object;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const response = props.order.fraudResponse as object;

    return (
        <Container className={classes.mainContainer}>
            <Grid container={true} className={classes.inner}>
                <Grid item={true} xs={12} className={classes.form}>
                    <Grid container={true} alignItems="center" justifyContent="space-between">
                        <Typography className={classes.heading}>Fraud Details:</Typography>
                    </Grid>

                    <Grid item={true} xs={12} className={classes.details}>
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Order Id:
                            </Typography>
                            <Typography component="span">#{props.order.orderId}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Request:
                            </Typography>
                            <JsonEditor
                                data={requestPayload}
                                collapse
                                restrictAdd
                                restrictDelete
                                restrictEdit
                                restrictTypeSelection
                            />
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Response:
                            </Typography>
                            <JsonEditor
                                data={response}
                                collapse
                                restrictAdd
                                restrictDelete
                                restrictEdit
                                restrictTypeSelection
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default OrderDetails;
