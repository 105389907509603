import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

import { AppTextField } from '../common/app-textfield';
import useAdminStyles from '../styles';
import { BlackListedEmailAttributes, GetBlackListedEmail } from '../../types/blackListedEmail';
import { BLACK_LISTED_EMAIL_REASONS } from '../../constants/blackListedEmails';

type KeyRecord = keyof BlackListedEmailAttributes;

type AddEditFormState = {
    isPhoneValid: null | boolean;
    blackListedEmail: GetBlackListedEmail;
    errors: {
        [key in KeyRecord]?: string | null;
    };
};

type AddEditFormProps = {
    onCancel: () => void;
    onSave: (blackListedEmail: BlackListedEmailAttributes) => void;
    blackListedEmail?: GetBlackListedEmail;
    isSaving: boolean;
};

const DEFAULT_BLACK_LISTED_EMAIL: BlackListedEmailAttributes = {
    email: '',
    reason: 'FRAUD'
};

const AddEditForm = (props: AddEditFormProps) => {
    const adminClasses = useAdminStyles();

    const { onCancel, onSave, blackListedEmail, isSaving } = props;

    const [state, setState] = useState<AddEditFormState>({
        isPhoneValid: true,
        blackListedEmail: DEFAULT_BLACK_LISTED_EMAIL,
        errors: {}
    });

    React.useEffect(() => {
        blackListedEmail &&
            setState((prevState) => ({
                ...prevState,
                blackListedEmail:
                    {
                        ...blackListedEmail
                    } || DEFAULT_BLACK_LISTED_EMAIL
            }));
    }, [blackListedEmail]);

    const saveBlackListedEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // validations
        const errors: AddEditFormState['errors'] = {};
        if (!state.blackListedEmail.email) {
            errors.email = 'Email is a required field';
        }

        const errorKeys = Object.keys(errors);
        if (errorKeys.length) {
            const input = document.querySelector(`input[name=blackListedEmail-${errorKeys[0]}]`);
            input?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start'
            });
            setState((prevState) => ({ ...prevState, errors }));
            return;
        }

        onSave(state.blackListedEmail);
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.form}>
            <Typography className={adminClasses.heading}>
                {' '}
                {(props.blackListedEmail && 'Update') || 'Create '} Black Listed Email{' '}
            </Typography>

            <form noValidate autoComplete="off" onSubmit={saveBlackListedEmail}>
                <Grid container={true} spacing={4}>
                    <Grid item={true} xs={12} sm={6}>
                        <AppTextField
                            className={adminClasses.textField}
                            required={true}
                            variant="filled"
                            size="small"
                            label="Email"
                            fullWidth={true}
                            value={state.blackListedEmail?.email || ''}
                            error={Boolean(state.errors.email)}
                            helperText={state.errors.email}
                            name="blackListedEmail-email"
                            onChange={(e) => {
                                const email = e.target.value.toLowerCase();
                                setState((prevState) => ({
                                    ...prevState,
                                    blackListedEmail: { ...prevState.blackListedEmail, email },
                                    errors: {
                                        ...prevState.errors,
                                        email: email ? undefined : 'Email is a required field'
                                    }
                                }));
                            }}
                        />
                    </Grid>

                    <Grid item={true} xs={12} sm={6}>
                        <FormControl variant="filled" className={adminClasses.textField} size="small">
                            <InputLabel id="select-order-user-type-label">Reason</InputLabel>
                            <Select
                                labelId="select-order-user-type-label"
                                id="select-order-user-type-helper"
                                name="blackListedEmail-reason"
                                value={state.blackListedEmail.reason}
                                label="Reason"
                                className="text-left"
                                onChange={(e) => {
                                    const reason = e.target.value as BlackListedEmailAttributes['reason'];
                                    setState((prevState) => ({
                                        ...prevState,
                                        blackListedEmail: {
                                            ...prevState.blackListedEmail,
                                            reason
                                        }
                                    }));
                                }}
                            >
                                {BLACK_LISTED_EMAIL_REASONS.map((reason) => (
                                    <MenuItem key={reason} value={reason}>{reason}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid item={true} xs={12} className={adminClasses.footer}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={(e) => {
                            onCancel();
                            setState({ ...state, blackListedEmail: DEFAULT_BLACK_LISTED_EMAIL });
                        }}
                        disabled={isSaving}
                    >
                        Cancel
                    </Button>

                    <Button variant="contained" color="primary" size="small" disabled={isSaving} type="submit">
                        save
                        {isSaving && <CircularProgress className="button-loader" />}
                    </Button>
                </Grid>
            </form>
        </Grid>
    );
};

export default AddEditForm;
