import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RGBColor } from 'react-color';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import AddIcon from '@material-ui/icons/Add';

import { ErrorType } from '../../types';

import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';

import useAdminStyles from '../styles';
import storeContext from '../../contexts/store-context';

import { scrollTop } from '../../utilities';
import ConfirmationDialog from '../common/confirmation-dialog';
import HasPermission from '../../utilities/can';
import AddEditForm from './form';
import { useUserService } from '../../contexts/user-context';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import ColorSwatch from './colorSwatch';

export const useStyles = makeStyles((theme) => ({
    textFieldGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 16,
        '& label': {
            textTransform: 'capitalize'
        },
        '& $second': {
            marginTop: 16,
            [theme.breakpoints.up(480)]: {
                marginLeft: 16,
                marginTop: 0
            }
        },
        [theme.breakpoints.up(480)]: {
            flexWrap: 'nowrap'
        }
    },
    label: {
        width: 'max-content',
        textTransform: 'capitalize',
        margin: '16px 0',
        fontWeight: 600,
        color: theme.palette.primary.main
    },
    heading: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        fontSize: 20,
        fontWeight: 600,
        '& $circularProgress': {
            marginLeft: 12
        }
    },
    subHeading: {
        fontSize: 20,
        fontWeight: 600
    },
    helperText: {
        display: 'block',
        fontStyle: 'italic',
        fontSize: 14,
        color: 'gray',
        opacity: 0.8
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    },
    circularProgress: {},
    footer: {},
    second: {},
    inner: {},
    userAvatar: {}
}));

const DEFAULT_COLOR: RGBColor = {
    r: 241,
    g: 112,
    b: 19,
    a: 1
};

const getData = (fraudScoreSlabs: FraudScoreSlabContextType, key: string) => {
    if (key === 'NEW') {
        return { accessStatus: null, riskScoreRange: [10, 10],  color: DEFAULT_COLOR };
    }
    
    return fraudScoreSlabs[key];
};

const generateFraudScoreSlabKey = (value: Array<number>) => `${value[0]}_TO_${value[1]}`;

export const FraudStatusTypeEnum = ['BLOCK', 'WATCH', 'ACCEPT'] as const;
export type FraudScoreSlabObjectType = {
    accessStatus: typeof FraudStatusTypeEnum[number] | null;
    color: RGBColor;
    riskScoreRange: Array<number>; // min and max value
};
type FraudScoreSlabContextType = Record<string, FraudScoreSlabObjectType>;
type State = {
    errorMessage: string | null;
    isLoading: boolean;
    isSaving: boolean;
    renderAddEditForm: boolean;
    isConfirmationDialogOpen: boolean;
    fraudScoreSlabs: FraudScoreSlabContextType;
    activeFraudScoreSlabKey: string | null;
    menuAnchorEle: HTMLElement | null;
};

const FraudScoreSlab = () => {
    const adminClasses = useAdminStyles();

    const history = useHistory();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const userService = useUserService();
    const { appAction } = storeContext();

    const canRead = HasPermission(userService.user, 'MANAGE_FRAUD_SCORE_SLAB', 'READ');
    const canCreate = HasPermission(userService.user, 'MANAGE_FRAUD_SCORE_SLAB', 'CREATE');
    const canUpdate = HasPermission(userService.user, 'MANAGE_FRAUD_SCORE_SLAB', 'UPDATE');
    const canDelete = HasPermission(userService.user, 'MANAGE_FRAUD_SCORE_SLAB', 'DELETE');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAll();
    }, []);

    const [state, setState] = useState<State>({
        fraudScoreSlabs: {} as FraudScoreSlabContextType,
        activeFraudScoreSlabKey: null,
        isLoading: false,
        isSaving: false,
        isConfirmationDialogOpen: false,
        renderAddEditForm: false,
        menuAnchorEle: null,
        errorMessage: null
    });

    const getAll = async () => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true }));

            const apiResponse = await apiService.get(`/api/admin/manage-fraud-score-slab/get-all`);
            const response = apiResponse.parsedBody;

            setState((prevState) => ({ ...prevState, isLoading: false }));

            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return;
            }
            setState((prevState) => ({ ...prevState, fraudScoreSlabs: response.data as FraudScoreSlabContextType }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const saveApi = async (apiMode: 'create' | 'update' | 'delete', fraudScoreSlabs: FraudScoreSlabContextType) => {
        try {
            setState((prevState) => ({ ...prevState, isSaving: true, menuAnchorEle: null, activeFraudScoreSlabKey: null }));
            const apiResponse = await apiService.post(`/api/admin/manage-fraud-score-slab/${apiMode}`, { fraudScoreSlabs });
            const response = apiResponse.parsedBody;
            setState((prevState) => ({ ...prevState, isSaving: false, isConfirmationDialogOpen: false }));

            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return;
            }

            setState((prevState) => ({ ...prevState, fraudScoreSlabs: response.data as FraudScoreSlabContextType, renderAddEditForm: false }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isSaving: false, menuAnchorEle: null }));
        }
    };

    const validateSlabs = (fraudScoreSlabs: Array<Array<number>>) => {
        let isValid = true;
        if (fraudScoreSlabs.length === 1) {
            return isValid;
        }

        for (let i = 0; i < fraudScoreSlabs.length; i++) {
            if (i === 0) {
                const [min, max] = fraudScoreSlabs[i];
                const [nextMin, nextMax] = fraudScoreSlabs[i + 1];
                if (max > nextMax) {
                    isValid = false;
                    break;
                }
                continue;
            }

            const [prevMin, prevMax] = fraudScoreSlabs[i - 1];
            const [min, max] = fraudScoreSlabs[i];
            if (min < prevMin || min === prevMin || min < prevMax || min === prevMax) {
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const onSave = (fraudScoreSlab: FraudScoreSlabObjectType) => {
        const newKey = fraudScoreSlab.accessStatus!;
        const fraudScoreSlabs = { ...state.fraudScoreSlabs, [newKey]: { ...fraudScoreSlab } };

        // sort slabs with respect to values.min
        const sortedScoreSlabs = Object.keys(fraudScoreSlabs)
            .map((key) => {
                const [min, max] = fraudScoreSlabs[key].riskScoreRange;
                return { min, key };
            })
            .sort((a, b) => a.min - b.min)
            .reduce((acc, ele) => {
                if (state.activeFraudScoreSlabKey === ele.key && state.activeFraudScoreSlabKey === newKey) {
                    return { ...acc, [ele.key]: fraudScoreSlabs[ele.key] };
                }
                if (state.activeFraudScoreSlabKey === ele.key) {
                    return acc;
                }
                return { ...acc, [ele.key]: fraudScoreSlabs[ele.key] };
            }, {} as FraudScoreSlabContextType);

        const sortedScoreValuesArray = Object.keys(sortedScoreSlabs).map(
            (key) => sortedScoreSlabs[key].riskScoreRange
        );
        const isRiskScoreValid = validateSlabs(sortedScoreValuesArray);

        if (!isRiskScoreValid) {
            const errorMessage = `Fraud score slabs are not valid, min and max are overlapping!`;
            toasterContext.setToaster({
                isOpen: true,
                message: errorMessage,
                severity: 'error'
            });
            setState((prevState) => ({
                ...prevState,
                menuAnchorEle: null,
                isConfirmationDialogOpen: false,
                errorMessage
            }));
            setTimeout(() => setState((prevState) => ({ ...prevState, errorMessage: null })), 6000);
            return;
        }
        saveApi((state.activeFraudScoreSlabKey === 'NEW' && 'create') || 'update', sortedScoreSlabs);
    };

    const headCells: Array<HeadCell> = [
        { id: 'accessStatus', label: 'Access Status' },
        { id: 'color', label: 'Color' },
        { id: 'riskScoreRange', label: 'Risk Score Range' },
        { id: 'options', label: '', align: 'right' }
    ];

    const tableRows: Array<TableResultRow> = Object.keys(state.fraudScoreSlabs).map((key) => {
        const data = state.fraudScoreSlabs[key];
        const [riskMin, riskMax] = data.riskScoreRange;
        return {
            id: {
                text: key
            },
            accessStatus: {
                align: 'left',
                text: data.accessStatus || '-'
            },
            color: {
                align: 'left',
                text: '',
                element: <ColorSwatch color={data.color} />
            },
            riskScoreRange: {
                align: 'left',
                text: `${riskMin}_TO_${riskMax}`
            },
            options: {
                align: 'right',
                text: '',
                element:
                    ((canUpdate || canDelete) && (
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            disabled={!!state.activeFraudScoreSlabKey}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (state.activeFraudScoreSlabKey) {
                                    return;
                                }

                                setState((prevState) => ({
                                    ...prevState,
                                    menuAnchorEle: e.currentTarget,
                                    activeFraudScoreSlabKey: key
                                }));
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )) ||
                    undefined
            }
        };
    });

    const headerComponent = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Button
                onClick={(e) =>
                    setState((prevState) => ({ ...prevState, renderAddEditForm: true, activeFraudScoreSlabKey: 'NEW' }))
                }
                size="small"
                color="primary"
                variant="contained"
            >
                <AddIcon />
                &nbsp;Add New Fraud Score Slab
            </Button>
        </Grid>
    );

    const handleMenuClose = () => {
        setState((prevState) => ({
            ...prevState,
            menuAnchorEle: null,
            activeFraudScoreSlabKey: null
        }));
    };

    const openAddEditForm = (activeFraudScoreSlabKey: string) => {
        if (!canUpdate) {
            return;
        }

        if (state.activeFraudScoreSlabKey && state.activeFraudScoreSlabKey === 'NEW') {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            activeFraudScoreSlabKey: activeFraudScoreSlabKey || null,
            renderAddEditForm: true,
            menuAnchorEle: null
        }));
        setTimeout(() => scrollTop());
    };

    const onDeleteConfirm = () => {
        if (!state.activeFraudScoreSlabKey) {
            setState((prevState) => ({
                ...prevState,
                isConfirmationDialogOpen: false,
                activeFraudScoreSlabKey: null,
                menuAnchorEle: null
            }));
            return;
        }

        const fraudScoreSlabs = state.fraudScoreSlabs;
        delete fraudScoreSlabs[state.activeFraudScoreSlabKey];
        saveApi('delete', fraudScoreSlabs);
    };

    const activeFraudScoreSlabKey =
        (state.activeFraudScoreSlabKey &&
            state.activeFraudScoreSlabKey !== 'NEW' &&
            state.fraudScoreSlabs?.[state.activeFraudScoreSlabKey]) ||
        null; 

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                {state.renderAddEditForm && state.activeFraudScoreSlabKey && (
                    <AddEditForm
                        errorMessage={state.errorMessage}
                        isSaving={state.isSaving}
                        id={state.activeFraudScoreSlabKey}
                        data={getData(state.fraudScoreSlabs, state.activeFraudScoreSlabKey)}
                        onSave={onSave}
                        onCancel={() =>
                            setState((prevState) => ({
                                ...prevState,
                                renderAddEditForm: false,
                                activeFraudScoreSlabKey: null,
                                errorMessage: null
                            }))
                        }
                    />
                )}

                <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                    {canUpdate && (
                        <MenuItem
                            onClick={() =>
                                state.activeFraudScoreSlabKey && openAddEditForm(state.activeFraudScoreSlabKey)
                            }
                        >
                            Edit
                        </MenuItem>
                    )}
                    {canDelete && (
                        <MenuItem
                            onClick={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                        >
                            Delete
                        </MenuItem>
                    )}
                </Menu>

                <TableComponent
                    rowHover={Boolean(!state.activeFraudScoreSlabKey)}
                    keyField="id"
                    selectedRows={undefined}
                    showSearch={false}
                    showCheckbox={false}
                    showPaginator={{ bottom: true }}
                    headerComponent={(!state.renderAddEditForm && canCreate && headerComponent) || undefined}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    onDelete={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    onCheckboxSelect={(selectedRows) => setState((prevState) => ({ ...prevState, selectedRows }))}
                    rowTooltip={(canUpdate && !state.activeFraudScoreSlabKey && 'Click to edit') || undefined}
                    onRowClick={(key) => openAddEditForm(key)}
                    fillEmptyRows={false}
                />
            </Container>

            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={`Click confirm to delete the ${
                    activeFraudScoreSlabKey || ''
                } fraud score slab. Changes can't be reverted.`}
                isLoading={state.isSaving}
                open={state.isConfirmationDialogOpen}
                onClose={() =>
                    setState((prevState) => ({
                        ...prevState,
                        isConfirmationDialogOpen: false,
                        activeFraudScoreSlabKey: null,
                        menuAnchorEle: null
                    }))
                }
                onConfirm={onDeleteConfirm}
            />
        </Grid>
    );
};

export default FraudScoreSlab;
