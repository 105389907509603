import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import AddIcon from '@material-ui/icons/Add';

import { GetPromoCode, PromoCode } from '../../types';

import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';

import useAdminStyles from '../styles';
import storeContext from '../../contexts/store-context';
import Switch from '@material-ui/core/Switch';

import moment from 'moment';
import { DateFormatTypeEnum, formatTimeStamp, joinNameParts, scrollTop } from '../../utilities';
import ConfirmationDialog from '../common/confirmation-dialog';
import HasPermission from '../../utilities/can';
import AddEditForm from './form';
import StatusButton from '../common/status-button';
import { useUserService } from '../../contexts/user-context';
import { BlackListedEmailAttributes, GetBlackListedEmail } from '../../types/blackListedEmail';

type State = {
    isLoading: boolean;
    isDeleting: boolean;
    isSaving: boolean;
    renderAddEditForm: boolean;
    menuAnchorEle: HTMLElement | null;
    activeId: number | null;
    blackListedEmails: GetBlackListedEmail[];
    selectedRows: TableResultRow[];
    isConfirmationDialogOpen: boolean;
};

const ManageBlackListedEmails = () => {
    const adminClasses = useAdminStyles();
    const history = useHistory();
    const { promoCodeAction, blackListedEmailsAction, appAction } = storeContext();

    const userService = useUserService();
    const canRead = HasPermission(userService.user, 'MANAGE_BLACK_LISTED_EMAILS', 'READ');
    const canCreate = HasPermission(userService.user, 'MANAGE_BLACK_LISTED_EMAILS', 'CREATE');
    const canUpdate = HasPermission(userService.user, 'MANAGE_BLACK_LISTED_EMAILS', 'UPDATE');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAllBlackListedEmails();
    }, []);

    const [state, setState] = useState<State>({
        isLoading: false,
        isDeleting: false,
        isSaving: false,
        renderAddEditForm: false,
        menuAnchorEle: null,
        activeId: null,
        blackListedEmails: [],
        selectedRows: [],
        isConfirmationDialogOpen: false
    });

    const activeBlackListedEmail = state.blackListedEmails.find((p) => p.id === state.activeId);

    const getAllBlackListedEmails = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const blackListedEmails = await blackListedEmailsAction()?.getAllBlackListedEmails();
        setState((prevState) => ({ ...prevState, blackListedEmails: blackListedEmails || [], isLoading: false }));
    };

    const headCells: Array<HeadCell> = [
        { id: 'email', label: 'Email' },
        { id: 'reason', label: 'Reason' },
        { id: 'userType', label: 'User Type' },
        { id: 'createdBy', label: 'Created By' },
        { id: 'createdAt', label: 'Created At' },
        { id: 'options', label: '' }
    ];

    const tableRows: Array<TableResultRow> = state.blackListedEmails.map((blackListedEmail) => {
        return {
            id: {
                text: blackListedEmail.id?.toString() || ''
            },
            email: {
                align: 'left',
                text: blackListedEmail.email || '-'
            },
            reason: {
                align: 'left',
                text: blackListedEmail.reason || '-'
            },
            userType: {
                align: 'left',
                text: blackListedEmail.userType || 'ADMIN'
            },
            createdBy: {
                align: 'left',
                text:
                    (blackListedEmail.adminUser
                        ? `${joinNameParts(blackListedEmail.adminUser) || blackListedEmail.adminUser.email} (#${
                              blackListedEmail.adminUser.userId
                          })`
                        : blackListedEmail.user
                        ? `${joinNameParts(blackListedEmail.user) || blackListedEmail.user.email} (#${
                              blackListedEmail.user.userId
                          })`
                        : '-') || '-'
            },
            createdAt: {
                align: 'left',
                text:
                    (blackListedEmail?.createdAt &&
                        formatTimeStamp(blackListedEmail?.createdAt, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)) ||
                    '-'
            },
            options: {
                align: 'right',
                text: '',
                helperText:
                    (blackListedEmail.updatedByAdminUser &&
                        `updated by - ${
                            blackListedEmail.updatedByAdminUser?.firstName
                                ? joinNameParts(blackListedEmail.updatedByAdminUser)
                                : blackListedEmail.updatedByAdminUser?.email || ''
                        }${blackListedEmail?.updatedAt && ' - '}${
                            blackListedEmail?.updatedAt && moment(new Date(blackListedEmail?.updatedAt)).fromNow()
                        }`) ||
                    undefined,
                element:
                    (canUpdate && (
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setState((prevState) => ({
                                    ...prevState,
                                    menuAnchorEle: e.currentTarget,
                                    activeId: blackListedEmail.id || null
                                }));
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )) ||
                    undefined
            }
        };
    });

    const headerComponent = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Button
                onClick={() => setState((prevState) => ({ ...prevState, renderAddEditForm: true, activeId: null }))}
                size="small"
                color="primary"
                variant="contained"
            >
                <AddIcon />
                &nbsp;Create New Black Listed Email
            </Button>
        </Grid>
    );

    const handleMenuClose = () => {
        setState((prevState) => ({
            ...prevState,
            menuAnchorEle: null,
            activeId: null
        }));
    };

    const saveBlackListedEmail = async (blackListedEmail: BlackListedEmailAttributes) => {
        setState((prevState) => ({ ...prevState, isSaving: true }));
        let hideAddEditForm = true;
        if (blackListedEmail.id) {
            hideAddEditForm = (await blackListedEmailsAction()?.updateBlackListedEmail(blackListedEmail)) || false;
        } else {
            hideAddEditForm = (await blackListedEmailsAction()?.createBlackListedEmail(blackListedEmail)) || false;
        }
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            isSaving: false,
            renderAddEditForm: !hideAddEditForm
        }));

        if (hideAddEditForm) {
            getAllBlackListedEmails();
        }
    };

    const openFormForEdit = (id: number) => {
        if (!canUpdate) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            renderAddEditForm: true,
            activeId: id,
            menuAnchorEle: null
        }));
        setTimeout(() => scrollTop());
    };

    const onDeleteConfirm = async () => {
        const ids: number[] = [];

        if (activeBlackListedEmail?.id) {
            ids.push(activeBlackListedEmail.id);
        } else if (state.selectedRows) {
            const idsToDelete = state.selectedRows.map((row) => +row.id.text);
            Array.prototype.push.apply(ids, idsToDelete);
        }

        setState((prevState) => ({ ...prevState, isDeleting: true }));
        await blackListedEmailsAction()?.deleteMultipleBlackListedEmails(ids);
        setState((prevState) => {
            const newBlackListedEmail = prevState.blackListedEmails.filter((p) => !ids.includes(p.id!));
            return {
                ...prevState,
                blackListedEmails: newBlackListedEmail,
                isDeleting: false,
                isConfirmationDialogOpen: false,
                menuAnchorEle: null
            };
        });
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                {state.renderAddEditForm && (
                    <AddEditForm
                        isSaving={state.isSaving}
                        blackListedEmail={activeBlackListedEmail}
                        onCancel={() => setState((prevState) => ({ ...prevState, renderAddEditForm: false }))}
                        onSave={saveBlackListedEmail}
                    />
                )}

                <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                    <MenuItem onClick={() => state.activeId && openFormForEdit(state.activeId)}>Edit</MenuItem>
                    <MenuItem
                        onClick={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    >
                        Delete
                    </MenuItem>
                </Menu>

                <TableComponent
                    headerComponent={(!state.renderAddEditForm && canCreate && headerComponent) || undefined}
                    showPaginator={{ bottom: true }}
                    showCheckbox={true}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    selectedRows={state.selectedRows}
                    onDelete={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    onCheckboxSelect={(selectedRows) => setState((prevState) => ({ ...prevState, selectedRows }))}
                    keyField="id"
                    rowTooltip={(canUpdate && 'Click to edit') || undefined}
                    onRowClick={(id) => openFormForEdit(+id)}
                    fillEmptyRows={false}
                />
            </Container>
            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={'Click CONFIRM to delete the email.'}
                isLoading={state.isDeleting}
                open={state.isConfirmationDialogOpen}
                onClose={() =>
                    setState({
                        ...state,
                        isConfirmationDialogOpen: false,
                        activeId: null,
                        menuAnchorEle: null
                    })
                }
                onConfirm={onDeleteConfirm}
            />
        </Grid>
    );
};

export default ManageBlackListedEmails;
