import { UserAccount } from './userAccount';

export const AdminAccountInactiveReasonEnum = [
    'INVITATION_PENDING',
    'ACCOUNT_TEMPORARILY_LOCKED',
    'ACCESS_REVOKED'
] as const;

export const AdminUserRoleEnum = ['ADMIN_USER'] as const;

export const PrivilegeTypeEnum = [
    'MANAGE_ADMIN_USER',
    'CATEGORY',
    'ML_CATEGORY',
    'PROMO_CODE',
    'APPROVE_BUSINESS_USER',
    'MANAGE_USER',
    'MANAGE_PRODUCT',
    'MANAGE_THEME',
    'MANAGE_PRICING',
    'COMPARE_PRODUCT',
    'MANAGE_FRAUD_SCORE_SLAB',
    'MANAGE_TRANSACTION_LIMIT_SETTINGS',
    'MANAGE_INFLUENCER',
    'MANAGE_INFLUENCER_REFERRAL_PAYMENT',
    'MANAGE_PAYOUT',
    'MANAGE_FUND_TRANSACTIONS',
    'MANAGE_RATINGS',
    'MANAGE_B2B_SUBSCRIPTION_PLAN',
    'MANAGE_BUSINESS_ORDER_PAYOUT',
    'MANAGE_BLACK_LISTED_EMAILS',
    'MANAGE_EXCHANGE_RATES'
] as const;
export const PrivilegeAccessTypeEnum = ['CREATE', 'UPDATE', 'DELETE', 'READ'] as const;

export type PrivilegeType = Record<typeof PrivilegeTypeEnum[number], Array<typeof PrivilegeAccessTypeEnum[number]>>;

export type AdminUser = Omit<UserAccount, 'inActiveReason' | 'role'> & {
    inActiveReason?: typeof AdminAccountInactiveReasonEnum[number] | null;
    role?: typeof AdminUserRoleEnum[number] | null;
    privileges: PrivilegeType;
};
